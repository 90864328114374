@media (max-width:1600px) {
    .login .login__form .login__form--outer .login__heading{
        font-size: 22px;
    }
}
@media (max-width:1400px) {
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner .tab-content{
        height: calc(100% - 188px);
    }
    // .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box{
    //     top: 125px;
    // }
}


@media (max-width:1200px) {
    .viewSampleRequest--wrapper .view-detail-for-admin .view-detail-inner .view-detail-body .right-inner,
    .viewSampleRequest--wrapper .view-detail-for-user .view-detail-inner .view-detail-body .right-inner  {
        height: calc(100% - 95px);
    }
    .viewSampleRequest--wrapper .admin-message-box .admin-message-box-inner .sidebar .seller-list .list-group {
        height: 360px;
    }
    .viewSampleRequest--wrapper .admin-message-box .admin-message-box-inner .chat .message-list-card .card .card-body{
        height: 500px;
    }
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .message-list-card .card .card-body{
        height: 490px;
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner {
        width: 75%;
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-left{
        width: 50%;
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner .barrel-selected-body{
        height: calc(100% - 205px);
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner .tab-content{
        height: calc(100% - 216px);
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner .tab-content{
        height: calc(100% - 216px);
    }
    .viewSampleRequest--wrapper .msgContainerBox,
    .viewSampleRequest--wrapper.view_request--wrapper .msgContainerBox{
        position: relative !important;
        width: auto !important;
        right: unset !important;
        top: unset !important;
    }
    .viewSampleRequest--wrapper .margin-right-400 {
        margin-right: unset;
    }
    .viewSampleRequest--wrapper .margin-right-430 {
        margin-right: unset;
    }
    .viewSampleRequest--wrapper .margin-right-435 {
        margin-right: unset;
    }
    .viewSampleRequest--wrapper .view-detail-for-admin .view-detail-inner .view-detail-body .left-inner .left-content,.viewSampleRequest--wrapper .view-detail-for-user .view-detail-inner .view-detail-body .left-inner .left-content {
        max-height: 300px;
    }
    // .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .sidebar .seller-list {
    //     height: 465px;
    // }
    .viewSampleRequest--wrapper .view-sample-request .inventory-file-list-table .service-detail-table-wrapper .service-detail-table thead tr th:first-child{
        min-width:"unset"
    }
    .viewSampleRequest--wrapper .view-sample-request .inventory-file-list-table .service-detail-table-wrapper .service-detail-table thead tr th {
        width: "unset";
    }
}
@media (max-width:1325px) {
    .usersList .usersList__header--wrapper.detail-page-header--wrapper {
        flex-direction: column;
        gap: 0.5rem;
    }
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box{
        top: 123px;
    }
}

@media (max-width:1100px) {
    .usersList .usersList__header--wrapper.detail-page-header--wrapper .usersList__header--inner{
        flex-direction: column;
    }
}
@media (max-width:991px) {
    .group-chat-wrapper .service--req-wrapper.group-chat-container .faq-offcanvas-wrapper .faq-panel-left {
        width: 25%;
    }
    .group-chat-wrapper .service--req-wrapper.group-chat-container .faq-offcanvas-wrapper .faq-panel-inner  {
        width: 75%;
    }
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box {
        top: 167px;
    }
    .support-main-wrapper .group-chat-container .group-list-wrapper .group-chat-nav-wrapper .create-ticket-btn{
        padding: 8px 16px;
    }
    .support-main-wrapper .group-chat-container .group-list-wrapper .group-chat-nav-wrapper .nav.nav-tabs .nav-link{
        padding-inline: 8px;
    }
    // .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box{
    //     top: 125px;
    // }
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .card .card-body{
        height: 550px;
    }
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .sidebar .seller-list{
        height: 500px;
    }
    // .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .sidebar .seller-list .list-group{
    //     height: 500px;
    // }
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .arrow-icon {
        bottom: -42px;
    }
    .group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .serched-inventory-modal .list-wrapper {
        max-height: 250px;
        height: unset;
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner .tab-content{
        height: calc(100% - 215px);
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-left{
        width: 25%;
    }
}
// @media (max-width:894px) {
//     .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box{
//         top: 172px;
//     }
// }
@media (max-width:767px) {
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner {
        width: 90%;
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-left{
        width: 10%;
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner .tab-content {
        height: calc(100% - 250px);
    }
    .viewSampleRequest--wrapper .view-detail-for-user .view-detail-inner,
    .viewSampleRequest--wrapper .view-detail-for-admin .view-detail-inner{
        width: 90%;
    }
    .viewSampleRequest--wrapper .view-detail-for-user .view-detail-left,
    .viewSampleRequest--wrapper .view-detail-for-admin .view-detail-left,
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-left-inner
    {
        width: 10%;
    }
    .viewSampleRequest--wrapper .view-detail-for-user .view-detail-inner .header .header-inner .main-heading {
        font-size: 20px;
        line-height: 26px;
    }
    .viewSampleRequest--wrapper .view-detail-for-user .view-detail-inner .view-detail-body .left-inner .left-heading p{
        font-size: 18px;
        line-height: 20px;
    }
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner {
        width: 90%;
    }
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .card .card-body{
        height: 580px;
    }
}
@media (max-width:666px) {
    // .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box{
    //     top: 228px;
    // }
    .viewSampleRequest--wrapper .view-detail-for-admin .view-detail-inner .view-detail-body .right-inner{
        height: calc(100% - 165px);
    }
}
@media (max-width:610px) {
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box{
        top: 200px;
    }
}
@media (max-width:576px) {
    .group-chat-wrapper .service--req-wrapper.group-chat-container .faq-offcanvas-wrapper .faq-panel-left {
        width: 10%;
    }
    .group-chat-wrapper .service--req-wrapper.group-chat-container .faq-offcanvas-wrapper .faq-panel-inner  {
        width: 90%;
    }
    .barrel-selection-offcanvas-wrapper .barrel-selection-inner .barrel-selected-body{
        height: calc(100% - 240px);
    }
    .viewSampleRequest--wrapper .view-detail-for-user .view-detail-inner .view-detail-body .right-inner {
        height: calc(100% - 120px);
    }
}
@media (max-width:374px) {
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box{
        top: 282px;
    }
}

@media screen and (min-width:1250px) and (max-width:1328px) {
    .dashboard__data--card{
        padding: 8px;
    }
    .dashboard__data{
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
    .dashboard__data--card .count{
        font-size: 14px;
        line-height: 16px;
        margin-top: 15px;
    }
    .dashboard__data--card .details{
        font-size: 12px;
        line-height: 14px;
    }
    .dashboard__data--card .MuiTypography-root{
        font-size: 12px;
    }
    .dashboard__data .dashboard__data--card>img,
    .dashboard__data--card.card-span img{
        height: 30px;
        width: 30px;
        object-fit: cover;
    }
    .dashboard__data--card .hand-pointer-wrapper{
        height: 30px;
        width: 30px;
    }
    .dashboard__data--card .hand-pointer-wrapper .pointer-count{
        top: -3px;
    right: -7px;
    height: 16px;
    width: 16px;
    font-size: 12px;
    line-height: 12px;
    }
    .dashboard__data--card{
        gap: 6px;
    }

}

@media screen and (min-width:1328px) and (max-width:1398px) {
    .dashboard__data--card{
        padding: 8px;
    }
    .dashboard__data{
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
    .dashboard__data--card .count{
        font-size: 14px;
        line-height: 16px;
        margin-top: 13px;
    }
    .dashboard__data--card .details{
        font-size: 12px;
        line-height: 14px;
    }
    .dashboard__data--card .MuiTypography-root{
        font-size: 12px;
    }
    .dashboard__data .dashboard__data--card>img,
    .dashboard__data--card.card-span img{
        height: 30px;
        width: 30px;
        object-fit: cover;
    }
    .dashboard__data--card .hand-pointer-wrapper{
        height: 30px;
        width: 30px;
    }
    .dashboard__data--card .hand-pointer-wrapper .pointer-count{
        top: -2px;
    right: -7px;
    height: 16px;
    width: 16px;
    font-size: 12px;
    line-height: 12px;
    }
    .dashboard__data--card{
        gap: 6px;
    }

}
@media screen and (min-width:1398px) and (max-width:1455px) {
    .dashboard__data--card{
        padding: 8px;
    }
    .dashboard__data{
        grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
    }
    .dashboard__data--card .count{
        font-size: 16px;
        line-height: 18px;
        margin-top: 12px;
    }
    .dashboard__data--card .details{
        font-size: 14px;
        line-height: 16px;
    }
    .dashboard__data--card .MuiTypography-root{
        font-size: 14px;
    }
    .dashboard__data .dashboard__data--card>img,
    .dashboard__data--card.card-span img{
        height: 35px;
        width: 35px;
        object-fit: cover;
    }
    .dashboard__data--card .hand-pointer-wrapper{
        height: 35px;
        width: 35px;
    }
    .dashboard__data--card .hand-pointer-wrapper .pointer-count{
        top: -1px;
    right: -7px;
    height: 16px;
    width: 16px;
    font-size: 12px;
    line-height: 12px;
    }
    .dashboard__data--card{
        gap: 7px;
    }

}
@media screen and (min-width:1455px) and (max-width:1599px) {
    // .dashboard__data--card{
    //     padding: 8px;
    // }
    .dashboard__data{
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
    .dashboard__data--card .count{
        font-size: 16px;
        line-height: 18px;
        margin-top: 12px;
    }
    .dashboard__data--card .details{
        font-size: 14px;
        line-height: 16px;
    }
    .dashboard__data--card .MuiTypography-root{
        font-size: 14px;
    }
    .dashboard__data .dashboard__data--card>img,
    .dashboard__data--card.card-span img{
        height: 35px;
        width: 35px;
        object-fit: cover;
    }
    .dashboard__data--card .hand-pointer-wrapper{
        height: 35px;
        width: 35px;
    }
    .dashboard__data--card .hand-pointer-wrapper .pointer-count{
        top: -3px;
    right: -7px;
    height: 16px;
    width: 16px;
    font-size: 12px;
    line-height: 12px;
    }
    // .dashboard__data--card{
    //     gap: 8px;
    // }

}
@media (min-width:1599px) and (max-width:1699px){
    .dashboard__data--card .count {
        font-size: 18px;
        line-height: 20px;
    }
}
@media screen and (max-width:440px) {
    .dashboard__data--card.card-span{
        grid-column: unset;
    } 
    .dashboard__data--card.card-span .total-inventory-wrapper{
        flex-direction: column;
    }
}
@media screen and (max-width:475px) {
    .rti--tag span {
        max-width: 215px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
@media (min-width:1600px) {
    // .dashboard__inner.custom-dashboard-inner .dashboard__messageBox-wrapper {
    //     height: 800px;
    // }
    .dashboard__data{
        grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
    }
}
@media screen and (min-width:1700px) {
    .dashboard__data{
        grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    }
}
// @media (min-width:1900px) {
//     .dashboard__inner.custom-dashboard-inner .dashboard__messageBox-wrapper {
//         height: 790px;
//     }
// }
@media (max-width:767px) {
    .modal.modal__wd--submitSellReq .modal-dialog .modal-content .modal-body .modal-body__upload-file .instruction-wrapper .left-part{
        border: unset;
    }
}

// For company Dashboard
@media (min-width:1200px ) {
    .company-dashboard__data.owner-data {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
    // .company-dashboard__data.owner-data .company-dashboard__data--card{
    //     padding: 10px;
    //     padding-top: 25px;
    // }
    .company-dashboard__data--card img {
        height: 30px;
        width: 30px;
    }
    .company-dashboard__data--card .count {
        font-size: 15px;
        line-height: 20px;
    }
    .company-dashboard__data--card .details{
        font-size: 13px;
        line-height: 16px;
    }
    .company-dashboard__data.buyer-data{
        grid-template-columns: repeat(auto-fill, minmax(227px, 1fr));
    }
}
@media (min-width:1230px) {
    .company-dashboard__data.owner-data {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
    // .company-dashboard__data.owner-data .company-dashboard__data--card,
    // .company-dashboard__data.buyer-data.company-dashboard__data--card{
    //     padding: 10px;
    //     padding-top: 25px;
    // }
    .company-dashboard__data--card img {
        height: 30px;
        width: 30px;
    }
    .company-dashboard__data--card .count {
        font-size: 15px;
        line-height: 20px;
    }
    .company-dashboard__data--card .details{
        font-size: 13px;
        line-height: 16px;
    }
}
@media (min-width:1248px) {
    .company-dashboard__data.owner-data {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }
}
@media (min-width:1398px){
    .company-dashboard__data.owner-data {
        grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    }
    .company-dashboard__data--card .count {
        font-size: 18px;
        line-height: 23.94px;
    }
    .company-dashboard__data--card .details{
        font-size: 15px;
        line-height: 19.95px;
    }
    .company-dashboard__data.buyer-data {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}
@media (min-width:1570px)  {
    .company-dashboard__data.owner-data {
        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    }
    .company-dashboard__data--card img {
        height: 40px;
        width: 40px;
    }
    // .company-dashboard__data.owner-data .company-dashboard__data--card,
    // .company-dashboard__data.buyer-data.company-dashboard__data--card{
    //     padding: 15px;
    //     padding-top: 25px;
    // }
    .company-dashboard__data.buyer-data {
        grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    }
}
@media (min-width:1720px) {
    .company-dashboard__data.owner-data {
        grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
    }
    .company-dashboard__data.buyer-data {
        grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    }
    .viewSampleRequest--wrapper .view-detail-for-admin{
        bottom: 0;
    }

}
@media (min-width:1920px) {
    .company-dashboard__data.owner-data {
        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    }
    .company-dashboard__data.buyer-data {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
}
@media (max-width:1199px) {
    // .company-dashboard__inner .company-dashboard__messageBox-wrapper.owner-data{
    //     height: unset;
    //     max-height: 500px;
    //     overflow-y: auto;
    // }
    // .company-dashboard__inner .company-dashboard__messageBox-wrapper.buyer-data{
    //     height: unset;
    //     max-height: 500px;
    //     overflow-y: auto;
    // }
}
@media (max-width:576px) {
    .company-dashboard__user .company-dashboard-user__nav--tabs-wrapper .nav-btn{
        padding: 10px 25px;
    }
    .group-chat-wrapper .group-chat-container{
        padding: 10px;
    }
    .support-main-wrapper .group-chat-container .group-list-wrapper .group-chat-nav-wrapper .create-ticket-btn {
        font-size: 14px;
        line-height: 16px;
        padding: 6px 14px;
    }
}
@media (min-width:1200px) {
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .chat__info--company{
        max-width: 22px;
    }
}
@media (min-width:1400px) {
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .chat__info--company{
        max-width: 57px;
    }
}
@media (min-width:1600px) {
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .chat__info--company{
        max-width: 165px;
    }
}
@media (min-width:1800px) {
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .chat__info--company{
        max-width: 200px;
    }
}
@media (min-width:1900px) {
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .chat__info--company{
        max-width: 210px;
    }
}
@media (max-width: 600px){
.viewSampleRequest--wrapper .view-detail-for-admin .view-detail-inner .view-detail-body .left-inner .left-content,
.viewSampleRequest--wrapper .view-detail-for-user .view-detail-inner .view-detail-body .left-inner .left-content {
    max-height: 285px;
}
}
// @media (min-width:1200px) and (max-width:1500px){
// .viewSampleRequest--wrapper .view-detail-for-user .view-detail-inner .view-detail-body .left-inner .left-content {
//     max-height: 560px;
//     overflow-y: auto;
// }
// }
@media (max-width:1399.98px) {
    .customer--view__cart .customer--view-cart-inner {
        min-height: calc(100vh - 362px);
    }
    .success-order-wrapper {
        min-height: calc(100vh - 266px);
    }
    .profile-wrapper {
        min-height: calc(100vh - 377px);
    }
}


// responsive Changes For all Pages

@media (max-width:1650px) {
    .wd__header.wd__header--user .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
        font-size: 15px;
    }
    .message-list-wrapper .message-list-card .message-box-header .css-1l2v7ki-MuiTypography-root,
    .message-list-wrapper .message-list-card .message-box-header .css-ew5bip-MuiTypography-root{
        font-size: 16px;
    }
    .group-chat-wrapper .group-chat-container .message-list-wrapper .btn-outline-offer {
        font-size: 13px;
    }
    .group-chat-wrapper .group-chat-container .message-list-wrapper .message-list-card .card .card-body .list-group .list-group-item .receiver__info,
    .group-chat-wrapper .group-chat-container .message-list-wrapper .message-list-card .card .card-body .list-group .list-group-item .sender__info  {
        font-size: 12px;
    }
    .group-chat-wrapper .group-chat-container .message-list-wrapper .message-list-card .card .card-body .list-group .list-group-item .receiver__msg pre, 
    .group-chat-wrapper .group-chat-container .message-list-wrapper .message-list-card .card .card-body .list-group .list-group-item .sender__msg pre{
        font-size: .800em;
    }
    .group-chat-wrapper .group-chat-container .group-chat-nav-wrapper .nav.nav-tabs .nav-link,
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .chat__info--name  {
        font-size: 14px;
    }
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .chat__info--company{
        font-size: 12px;
    }
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .chat__info--reqType{
        font-size: 12px;
    }
    .group-chat-wrapper .group-chat-container .group-list-wrapper .list-group .list-group-item .chat-info-wrapper .msg-preview {
        font-size: 13px;
    }

.Typesense__container .table__heading{
    font-size: 20px;
}
.grid-view.inventory__grid-view .grid-item .card .card-body .card-title h5 {
    font-size: 17px;
}
.grid-view.inventory__grid-view .grid-item .card .card-body .card-text .grid-cell {
    font-size: 14px;
}
.grid-view.inventory__grid-view .grid-item .card .card-body .details{
    font-size: 13px;
}
.grid-view.inventory__grid-view .grid-item .card .card-body .details .grid-cell {
    margin-bottom: 5px;
}
.Typesense__container .Typesense__filters-wrapper .filters--type .filter .filter--title {
    font-size: 15px;
}
.filter .BTW8mG_spectrum-Slider-labelContainer{
    font-size: 14px;
}
.Typesense__container .Typesense__filters-wrapper .filters--type .filter .ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-labelText {
    font-size: 14px;
}
.company-dashboard__data--card .details {
    font-size: 14px;
}
.company-dashboard__data--card .count{
    font-size: 17px;
}
.custom-table-outter .dashboard__inner .table-responsive .css-7li2lt .data-grid .MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-cell {
    font-size: 13px;
}
.company-dashboard-user__nav--tabs-wrapper button span {
font-size: 14px;
}
.custom-table-outter h5{
    font-size: 19px;
}
.usersList .data-grid .MuiDataGrid-cell {
font-size: 13px;
}
.usersList .data-grid .table-header .form-control{
    font-size: 14px;
}
.usersList .data-grid .table-header .form-control::placeholder{
    font-size: 14px;
}
.usersList .header--title h3.MuiTypography-root {
    font-size: 16px !important;
}
.orderRequest-inv-table thead tr:first-child th,
.orderRequest-inv-table thead tr:nth-child(2) th ,
.orderRequest-inv-table tbody tr td,
.viewSampleRequest--wrapper .view-sample-request .inventory-file-list-table .service-detail-table-wrapper .service-detail-table tbody tr td {
    font-size: 13px;
}
.orderRequest-inv-table tbody tr td .MuiTypography-root{
    font-size: 16px;
}
.viewSampleRequest--wrapper .orderDetail-footer-content-wrapper .title-bar h3,
.recentActivity .title-bar h3 {
    font-size: 19px;
}
.viewSampleRequest--wrapper .orderDetail-footer-content-wrapper .title-bar button.primary-btn{
    font-size: 15px;
}
.history-item .MuiTypography-root{
font-size: 14px;
}

.dashboard__data--card .details {
    font-size: 14px;
}
.dashboard__data--card .count{
    font-size: 17px;
}
.dashboard__data--card .MuiTypography-root{
    font-size: 12px;
}
.modal.modal__wd--submitSellReq .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 22px;
}
.modal.modal__wd--submitSellReq .modal-dialog .modal-content .modal-header .modal-text{
    font-size: 16px;
    line-height: 20px;
}
.modal-body__upload-file .form__inner .basic-faq .question-text{
    font-size: 14.6px !important;
}
.modal-body__upload-file .form__inner .basic-faq .custom-radio-container .custom-radio-label{
    font-size: 14px;
}
.modal-body__upload-file .form__inner .basic-faq .form-label,
.modal-body .basic-faq .form-label{
    font-size: 14.5px;
}
.modal-body__upload-file .form__inner .basic-faq .form-control,
.modal-body__upload-file .form__inner .basic-faq .form-control::placeholder{
    font-size: 14px;
}

.secondary-btn,
.primary-btn {
    font-size: 14.3px;
    align-items: center;
}
.usersList .usersList__header--wrapper h1.MuiTypography-root,
.group-chat-wrapper h1.MuiTypography-root,
.usersList .usersList__header--wrapper .header--title h1.title,
.dashboard .dashboard__user .dashboard__user--title,
.add-company-user-wrapper .title h2 {
    font-size: 22px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .sidebar .buyer-info-wrapper .buyer-text{
    font-size: 13px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .sidebar .buyer-info-wrapper .buyer-name{
    font-size: 16px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .sidebar .seller-list .seller-list-header .heading {
    font-size: 15px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .sidebar .seller-list .list-group .seller .seller-inner .seller-name {
    font-size: 16.5px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .sidebar .seller-list .list-group .seller .seller-inner .seller-item-list .seller-list-item {
    font-size: 13px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .message-list-card .card .card-body .list-group .list-group-item .sender .sender__info .sender__info--name,
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .message-list-card .card .card-body .list-group .list-group-item .receiver .receiver__info .receiver__info--time {
    font-size: 12px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .message-list-card .card .card-body .list-group .list-group-item .sender .sender__msg pre ,
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .message-list-card .card .card-body .list-group .list-group-item .receiver .receiver__msg pre{
    font-size: 13px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .message-list-card .card .card-body .list-group .list-group-item .receiver .sender__info--name{

}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box .admin-message-box-inner .chat .message-list-card .card .card-body .list-group .list-group-item .sender .sender__msg{
    padding: 8px 12px;
}
.dashboard .unread-message-wrapper .dashboard__messageBox--wrapper .user__name {
    font-size: 14.5px;
}
.dashboard .unread-message-wrapper .dashboard__messageBox--wrapper .user__companyname{
    font-size: 12px;
}
.dashboard .unread-message-wrapper .dashboard__messageBox--wrapper .user__companyname{
    font-size: 12px;
}
.dashboard .unread-message-wrapper .dashboard__messageBox--wrapper .user__message {
    font-size: 12.5px;
}
.modal__wd.modal .modal-dialog .modal-content .modal-body .modal-body__title {
    font-size: 14.5px;
}
.form__inner .form-control {
    font-size: 14px;
}
.form__inner .form-control::placeholder {
    font-size: 14px;
}
.add-company-user-wrapper .add-company-user-form-wrapper .form__inner .form-label,
.form__inner .form-label {
    font-size: 14.5px;
}
.add-company-user-wrapper .add-company-user-form-wrapper .form__inner .form-check .form-check-label span{
    font-size: 18px;
}
.add-company-user-wrapper .add-company-user-form-wrapper .form__inner .form__btn--group .secondary-btn,
.add-company-user-wrapper .add-company-user-form-wrapper .form__inner .form__btn--group .primary-btn {
    padding: 8px 20px;
}
.usersList .usersList__header--wrapper .header--menu .header--menu-items .menu-items__btnGroup .white-outline-btn{
    font-size: 14.5px;
}
.profile-admin .profile-wrapper .profile-details .profile-menu,
.profile-user .profile-wrapper .profile-details .profile-menu{
font-size: 14.5px;
}
.usersList .user-table-container .userTable.inventoryFilesTable .title-bar h3 {
    font-size: 18.5px;
}
.inventoryFilesTable .MuiList-root .MuiListItem-root .MuiListItemText-root span{
    font-size: 15px;
}
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .form-label,
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .select-dropdown,
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .select-dropdown::placeholder,
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .serched-inventory-modal .list-wrapper .list-item-wrapper .list-inventory-title,
.private-sell-dropdown .private-sell-dropdown-item{
    font-size: 14.5px;
}
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .serched-inventory-modal .searched-inventory-modal--right .barrel-list-wrapper .inventery-barrel-wrapper .barrel-number--inner .barrel-number ,
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .selected-inventory-detail-wrapper .selected-inventory-detail-inner .header-wrapper .remove-btn {
    font-size: 14px;
}
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .selected-inventory-detail-wrapper .selected-inventory-detail-inner .header-wrapper .detail-heading,
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .selected-inventory-detail-wrapper .selected-inventory-detail-inner .header-wrapper .total-quantity{
    font-size: 18px;
}
.group-chat-wrapper .service--req-wrapper.group-chat-container .service-req--inner .service-req-form-wrapper .service-req-form .form__field--wrapper .selected-inventory-detail-wrapper .selected-inventory-detail-inner .header-wrapper{
    padding: 10px 12px;
}
.viewSampleRequest--wrapper .view-sample-request .inventory-file-list-table .service-detail-right .content-wrapper .content-title,
.viewSampleRequest--wrapper .view-sample-request .inventory-file-list-table .service-detail-right .content-wrapper .content-text {
    font-size: 13px;
}
.wd__header--user .header__banner .back__button h3{
font-size: 24px;
}
.offer-req-container .list-group .list-group-item .title {
    font-size: 18px;
}
.offer-req-container .list-group .list-group-item .sub-title,
.offer-req-container .list-group .list-group-item .info{
    font-size: 13px;
}
.modal.modal__wd--submitSellReq .modal-dialog .modal-content .modal-body .modal-body__upload-file .upload--faq-header{
    font-size: 20px;
}
.modal.modal__wd--submitSellReq .modal-dialog .modal-content .modal-body .modal-body__upload-file .upload_csv--user__faq .accordion .accordion-item .accordion-header button ,
.modal.modal__wd--submitSellReq .modal-dialog .modal-content .modal-body .modal-body__upload-file .upload_csv--user__faq .accordion .accordion-item .accordion-collapse .accordion-body{
    font-size: 14.5px;
}
.modal.modal__wd--submitSellReq .modal-dialog .modal-content .modal-body .modal-body__upload-file .upload_csv--user__faq .accordion .accordion-item .accordion-collapse .accordion-body{
    padding: 10px;
}

.barrel-selection-offcanvas-wrapper .barrel-selection-inner .header .header-inner .main-heading {
    font-size: 20px;
}
.barrel-selection-offcanvas-wrapper .barrel-selection-inner .header .header-inner .sub-heading,
.barrel-selection-offcanvas-wrapper .barrel-selection-inner .header .sub-header-inner .search-input-wrapper input ,
.barrel-selection-offcanvas-wrapper .barrel-selection-inner .header .sub-header-inner .search-input-wrapper input ::placeholder {
    font-size: 13px;
}
.barrel-selection-offcanvas-wrapper .barrel-selection-inner .header .sub-header-inner .header-field-wrapper .header-field-label,
.barrel-selection-offcanvas-wrapper .barrel-selection-inner .nav .nav-link,
.barrel-selection-offcanvas-wrapper .barrel-selection-inner .tab-content .tab-pane .barrel-selection-body .body-field-wrapper .body-field-label {
    font-size: 14px;
}
.barrel-selection-offcanvas-wrapper .barrel-selection-inner .tab-content .tab-pane .barrel-selection-body .barrel-list-wrapper .barrel-list-inner .barrel-item-wrapper {
    font-size: 13.5px;
}
}
// Inbox Custom Width
@media (min-width:1300px){
    .group-list-wrapper.col-xl-4{
        width: 30%;
    }
    .message-list-wrapper.col-xl-8{
        width: 70%;
    }
}
// @media (max-width:1269px) {
//     .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box {
//         top: 125px;
//     }
// }
// @media (max-width:932px) {
//     .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box {
//         top: 160px;
//     }
// }
// @media (max-width:749px) {
// .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box {
//     top: 200px;
// }
// }
@media (max-width:450px) {
    .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box {
        top: 240px;
    }
    }
    @media (max-width:379px) {
        .usersList .usersList__header--wrapper .header--menu .header--menu-items .admin-message-box {
            top: 324px;
        }
        } 
@media (min-width:1500px) {
    .group-chat-container .container{
        max-width: 1450px;
    }
}

@media (min-width:1325px) {
    .usersList .usersList__header--wrapper.detail-page-header--wrapper {
        gap: 1.5rem;
    }
}

@media (max-width:1100px) {
    .usersList .usersList__header--wrapper.detail-page-header--wrapper .usersList__header--inner{
        flex-direction: column;
    }
}










