.barrel-selection-offcanvas-wrapper {
    position: absolute;
    top: 0px;
    right: -20px;
    left: 0px;
    bottom: 0px;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.3411764706);
    display: flex;
    justify-content: end;
    border-radius:20px 0 0 20px ;

    .barrel-selection-left {
        background-color: transparent;
        width: 50%;
    }

    .barrel-selection-inner {
        width: 50%;
        background-color: #FFFFFF;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 20px;
        position: relative;
        height: 100%;

        .header {
            border-bottom: 1px solid #D9D9D9;
            padding-bottom: 15px;

            .header-inner {
                .close-btn {
                    color: #8F9195;
                    background: transparent;
                    border: unset;
                    box-shadow: unset;
                }

                .main-heading {
                    font-family: Segoe UI;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 31.92px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #333333;
                }

                .sub-heading {
                    font-family: Segoe UI;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 19.95px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #737376;
                }
            }

            .sub-header-inner {
                .search-input-wrapper {
                    input {
                        border: 1px solid #E4E4DD;
                        background-color: #FFFFFF;
                        border-radius: 100px;
                        padding: 10px 10px 10px 45px;
                        font-family: Segoe UI;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 21.28px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #6F6F6F;

                        &::placeholder {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 21.28px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #6F6F6F;
                        }
                    }
                }

                .header-field-wrapper {
                    align-items: center;
                    display: flex;
                    flex-wrap: nowrap;

                    .header-field-label {
                        font-family: Segoe UI;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 21.28px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #333333;
                        white-space: nowrap;
                    }
                }
                .selected-barrels-count--wrapper{
                    font-family: Segoe UI;
font-size: 16px;
font-weight: 400;
line-height: 21.28px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
 span{
    font-weight: 700;
 }
                }
            }
        }

        .nav {
            border-bottom: 1px solid #D9D9D9;

            // .nav-item {
                .nav-link{
                font-family: Segoe UI;
                font-size: 16px;
                font-weight: 600;
                line-height: 21.28px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #737376;
                padding: 12px 20px;
                &.active{
                    font-weight: 700;
                    color: #3A809E;
                    border-bottom:2px solid #3A809E;
                }
                &:hover{
                    border-bottom:2px solid #3A809E;
                    font-weight: 700;
                    color: #3A809E;
                }
            }
            // }
        }

        .tab-content {
            height: calc(100% - 159px);

            .tab-pane {
                height: 100%;

                .barrel-selection-body {
                    padding-block: 15px;
                    height: calc(100% - 55px);

                    .body-field-wrapper {
                        .body-field-label {
                            font-family: Segoe UI;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 21.28px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #333333;
                        }
                    }

                    .barrel-list-wrapper {
                        margin-block: 10px;
                        height: calc(100% - 40px);
                        overflow: auto;

                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: transparent;
                            border-radius: 5px;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #D9D9D9;
                            border-radius: 5px;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }

                        .barrel-list-inner {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 8px;

                            .barrel-item-wrapper {
                                display: flex;
                                align-items: center;
                                padding: 3px;
                                border: 1px solid #D2D2D2;
                                border-radius: 5px;
                                font-family: Segoe UI;
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 22px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #616161;
                                cursor: pointer;

                                .lot-id-box {
                                    background-color: #F0F0F0;
                                    border-radius: 0 5px 5px 0;
                                    padding: 3px;
                                    font-weight: 600;
                                }

                                &.selected {
                                    color: #3A809E;
                                    border: 1px solid #3A809E;

                                    .lot-id-box {
                                        background-color: #E2F6FF;
                                    }
                                }
                            }
                        }
                    }
                }

                .barrel-selection-footer {
                    padding-block: 15px;
                    border-top: 1px solid #D9D9D9;

                    .done-btn {
                        font-family: Segoe UI;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #3A809E;
                        border: unset;
                        box-shadow: unset;
                        outline: unset;
                        background-color: transparent;
                    }
                }
            }
        }
        // for ownership transfer barrel selection panel

                .barrel-selected-body {
                    padding-block: 15px;
                    height: calc(100% - 175px);
                    overflow-y: auto;
                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: transparent;
                        border-radius: 5px;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #D9D9D9;
                        border-radius: 5px;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                    .selected-barrel-list-wrapper{
                        margin-bottom: 20px;
                    .selected-barrel-list-heading{
                    font-family: Segoe UI;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 21.28px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #3A809E;
                    margin-bottom: 10px;
                }
                        .selected-barrel-list-inner {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 8px;

                            .selected-barrel-list-item-wrapper {
                                display: flex;
                                align-items: center;
                                padding: 3px;
                                border-radius: 5px;
                                font-family: Segoe UI;
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 22px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #3A809E;
                                border: 1px solid #3A809E;

                                .lot-id-box {
                                    background-color: #E2F6FF;
                                    border-radius: 0 5px 5px 0;
                                    padding: 1px 2px;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
                }

                .barrel-selection-footer {
                    padding-block: 15px;
                    border-top: 1px solid #D9D9D9;

                    .done-btn {
                        font-family: Segoe UI;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #3A809E;
                        border: unset;
                        box-shadow: unset;
                        outline: unset;
                        background-color: transparent;
                    }
                }
            }
