@import '../../sass/style.scss';

.users {
    background-color: #EAECEE;
    border-radius: 35px 35px 0px 0px;
}

.usersList {
    font-family: 'Segoe UI Regular';
    border-radius: unset;

    .usersList__header--wrapper {
        padding-inline: 20px;
        .upload-spinner-wrapper{
            .spinner-border{
                margin-right: -5px !important;
                margin-left: 10px !important;
                margin-top: 8px;
                color: #719fda;
            }
        }
        .header--title {
            .title {
                font-size: 25px;
                font-weight: 600;
                line-height: 34px;
            }
        }

        .header--menu {
            .header--menu-items {
                .menu-items__search {
                    input[type=search] {
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        border: 0;
                    }
                }

                .menu-items__btnGroup {
                    .white-outline-btn {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 16px;
                        line-height: 1;
                    }

                    button {
                        .review--number {
                            font-size: 12px;
                            font-weight: 400;
                            height: 24px;
                            width: 24px;
                        }

                        // &.bg-white {
                        //     .review--number {
                        //         background-color: #EAECEE!important;
                        //     }
                        // }
                    }

                    &.btnGroup-border {
                        border-left: 1px solid $pastelGray;
                        border-right: 1px solid $pastelGray;
                    }

                    .counting {
                        background: $transparent;
                        color: $primaryTextColor;
                        font-family: 'Segoe UI Regular';
                        font-weight: 700;
                        line-height: 1;
                        padding: 8px 16px;
                        border: 1px solid $white;
                        border-radius: 50%;
                        // height: 40px;
                        // width: 40px;
                    }
                }
                .msg-count-wrapper{
                    background-color: #F8DFA1;
                    padding: 5px;
                    border-radius: 50%;
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    height: 18px;
                    width: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Segoe UI;
font-size: 10px;
font-weight: 600;
line-height: 10px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
                }
                .arrow-icon{
                    position: absolute;
                    right: -10px;
                    bottom: -41px;
                    font-size: 60px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.inventory__sell--wrapper {
    background: $white;
    border-radius: 25px;
}

.recentActivity {
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 15px;
    .title-bar {
        // border-bottom: 1px solid #C8CAC4;
        padding-block: 11px;

        h3 {
            color: $primaryTextColor;
            font-weight: 700;
            font-size: 20px;
        }
    }
    .history-stepper-wrapper{
        height: 262px;
        .MuiPaper-root{
            height: 100%;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 5px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #D9D9D9;
                border-radius: 5px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
            background-color: unset;
            box-shadow: unset;
            border-radius: unset;
            padding: unset !important;
        }
    }
}

.viewSampleRequest--wrapper {
    background: $white;
    border-radius: 25px;
    position: relative;
    &.viewOrderRequest--wrapper {
            background-color: unset;
    
            .msgContainerBox {
                .msgbox-container {
                    &.show {
                        .card {
                            .card-body {
                                .list-group {
                                    height: 680px;
                                    overflow-y: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
        .viewOfferRequest--wrapper {
            background-color: unset;
    
            .msgContainerBox {
                .msgbox-container {
                    &.show {
                        .card {
                            .card-body {
                                .list-group {
                                    height: 635px;
                                    overflow-y: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.viewServiceRequest--wrapper {
            background-color: unset;
    
            .msgContainerBox {
                top: 16px !important;
                .msgbox-container {
                    &.show {
                        .card {
                            .card-body {
                                .list-group {
                                    height: 650px !important;
                                    overflow-y: auto;
                                }
                            }
                        }
                    }
                }
                &.msgContainerBox-upload{
                    top: 0px !important;
                    bottom: 0;
                    .msgbox-container {
                        &.show {
                            .card {
                                .card-body {
                                    .list-group {
                                        height: 590px !important;
                                        overflow-y: auto;
                                    }
                                }
                            }
                        }
                    } 
                }
            }
        }
    .margin-right-430{
        margin-right: 430px;
    }
    .margin-right-400{
        margin-right: 400px;
    }
    .margin-right-435{
        margin-right: 435px;
    }
.orderDetail-footer-content-wrapper{
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 15px;
    .title-bar {
        h3 {
            color: #333333;
            font-weight: 700;
            font-size: 20px;
        }
    }
}

    .view-sample-request {
        margin-bottom: 14px;
        .sample-request-faq {
            border-bottom: 1px solid #F0F0F0;

            .faq-title {
                color: #8F9195;
                font-weight: 700;
                font-size: 14px;

                .ul-title {
                    background-color: #3A809E;
                    height: 2px;
                    width: 40px;
                }
            }

            .faq-desc {
                color: #333333;
                font-size: 14px;
            }
        }
        .inventory-file-list-table{
            background-color: #FFFFFF;
            border-radius: 20px;
            padding: 20px;
            // padding-bottom: 0px;
            .service-detail-table-wrapper{
                height: 370px;
                overflow-y: auto;
                padding-right: 8px;
                &::-webkit-scrollbar {
                    width: 5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: transparent;
                    border-radius: 5px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #D9D9D9;
                    border-radius: 5px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #D9D9D9;
                }
            .service-detail-table {
                thead {
                    position:sticky;
                    top: 0;
                    z-index: 1;
                    background-color: #F0F0F0;
                    border-radius: 8px;
                    tr {
                        border: unset;
                        border-bottom: 1px solid transparent;
    
                        th {
                            font-family: Segoe UI;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18.62px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #6F6F6F;
                            background-color: #F0F0F0;
                            padding: 10.46px 8px;
                            width:300px;
    
                            &:first-child {
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;
                                min-width:500px
                            }
    
                            &:last-child {
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                            }
                        }
                    }
                }
    
                tbody {
                    tr {
                        border-bottom: 1px solid #E4E4DD;
                        border-top: unset;
    
                        td {
                            padding: 20px 10px;
                            font-family: Segoe UI;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18.62px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #333333;
    
                            .barrel-count-btn {
                                background-color: #F7F7F7;
                                border: 1px solid #8F9195;
                                border-radius: 50px;
                                padding: 5px 8px;
                                font-family: Segoe UI;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18.62px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #737376;
                            }
                        }
    
                        &.total-barrel-wrapper {
                            border: 0px solid transparent;
                            border-bottom: 1px solid #E4E4DD;
    
                            td {
                                padding: 0 0 15px 0;
    
                                .accordion {
                                    .accordion-item {
                                        border: 0;
                                        border-radius: 10px;
    
                                        .accordion-collapse {
                                            .accordion-body {
                                                background-color: #F0F0F0;
                                                border-radius: 10px;
    
                                                .total-barrel-list-wrapper {
                                                    max-height: 125px;
                                                    overflow-y: auto;
                                                    overflow-x: hidden;
                                                    padding-right: 8px;
    
                                                    &::-webkit-scrollbar {
                                                        width: 5px;
                                                    }
    
                                                    /* Track */
                                                    &::-webkit-scrollbar-track {
                                                        background: #f1f1f1;
                                                        border-radius: 5px;
                                                    }
    
                                                    /* Handle */
                                                    &::-webkit-scrollbar-thumb {
                                                        background: #D9D9D9;
                                                        border-radius: 5px;
                                                    }
    
                                                    /* Handle on hover */
                                                    &::-webkit-scrollbar-thumb:hover {
                                                        background: #D9D9D9;
                                                    }
    
                                                    .barrel-list {
                                                        // display: grid;
                                                        // grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
                                                        // gap: 1rem;
    
                                                        .barrel-list-item {
                                                            font-family: Segoe UI;
                                                            font-size: 15px;
                                                            font-weight: 400;
                                                            line-height: 22px;
                                                            text-align: left;
                                                            text-underline-position: from-font;
                                                            text-decoration-skip-ink: none;
                                                            background-color: #FFFFFF;
                                                            border: 1px solid #8F9195;
                                                            border-radius: 5px;
                                                            padding: 5px;
                                                            color: #616161;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    
            .service-detail-right {
                .content-wrapper {
                    padding-block: 10px;
                    border-bottom: 1px solid #F0F0F0;
                    height: 100%;
                    .content-title {
                        font-family: Segoe UI;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 18.62px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #8F9195;
                        margin-bottom: 5px;
                    }
    
                    .title-bottom-line {
                        height: 2px;
                        width: 40px;
                        background-color: #3A809E;
                        margin-bottom: 5px;
                    }
    
                    .content-text {
                        font-family: Segoe UI;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 18.62px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #333333;
                        align-items: center;
                        display: flex;
                    }
                }
                &.bluk-upload-right{
                    background-color: white;
                    height: calc(100% - 0px);
                    border-radius: 20px;
                    padding: 15px;
                    .title-bar{
                        h3{
                        color: #333333;
    font-weight: 700;
    font-size: 20px;
}
                    }
                }
                                &.bluk-upload-full {
                                    padding: 20px;
                                    height: 410px;
                                    // .col{
                                    //     &:nth-last-child(-n+4){
                                    //         .content-wrapper {
                                    //             border-bottom: unset;
                                    //         }
                                    //     }
                                    //     &:nth-child(n+5){
                                    //         .content-wrapper {
                                    //             border-bottom: unset;
                                    //         }
                                    //     }
                                    .content-wrapper {
                                        padding-block: 15px 40px;
                                        border-bottom: 1px solid #F0F0F0;
                                        height: 100%;
                                        
                                        .content-title {
                                            font-family: Segoe UI;
                                            font-size: 14px;
                                            font-weight: 700;
                                            line-height: 18.62px;
                                            text-align: left;
                                            text-underline-position: from-font;
                                            text-decoration-skip-ink: none;
                                            color: #8F9195;
                                            margin-bottom: 10px;
                                        }
                
                                        .title-bottom-line {
                                            height: 2px;
                                            width: 40px;
                                            background-color: #3A809E;
                                            margin-bottom: 15px;
                                        }
                
                                        .content-text {
                                            font-family: Segoe UI;
                                            font-size: 14px;
                                            font-weight: 700;
                                            line-height: 18.62px;
                                            text-align: left;
                                            text-underline-position: from-font;
                                            text-decoration-skip-ink: none;
                                            color: #8F9195;
                                            // margin-bottom: 20px;
                                        }
                                        .download-icon-btn{
                                            border: unset;
                                            padding: unset;
                                            background-color: unset;
                                            outline: unset;
                                            box-shadow: unset;
                                        }
                                        .upload-icon-btn{
                                            border: unset;
                                            padding: unset;
                                            background-color: unset;
                                            outline: unset;
                                            box-shadow: unset;
                                            svg{
                                                height: 20px;
                                                width: 20px;
                                                margin-top: -7px;
                                            }
                                        }
                                    }
                                // }
                                }
            }
            .total-row--wrapper{
                border-top: 1px solid #D9D9D9;
            .total--amount-wrapper{
                font-family: Segoe UI;
font-size: 18px;
font-weight: 700;
line-height: 23.94px;
text-align: right;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
padding: 10px;
.shipping-info{
    font-family: Segoe UI;
font-size: 12px;
font-weight: 400;
line-height: 15.96px;
text-align: right;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
}
            }
            .amount-wrapper{
                font-family: Segoe UI;
font-size: 16px;
font-weight: 600;
line-height: 21.28px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
padding: 10px;
            }
        }
        }
    }
    &.view_request--wrapper{
    .msgContainerBox{
        position: absolute;
        top: 0px;
        right: 10px;
        bottom:0;
        // height: calc(100% - 35px);
        // max-width: 410px;
        .msgbox-container{
            &.show{
                .card{ 
                    .card-body{ 
                        .list-group{
                            height: 475px;
                        }
                }
            }
            }
            &.user-msgbox-container{
                            &.show {
                              .card {
                                .card-body {
                                    .list-group {
                                         height: 435px;
                            }
                        }
                    }
                }
                        }
        }
        &.view-sample-msgContainerBox{
            position: absolute;
            top: 0px;
            right: 10px;
            bottom: 0;
            //height: calc(100% - 35px);
            // max-width: 410px;
            .msgbox-container{
                &.show{
                    .card{ 
                        .card-body{ 
                            .list-group{
                                height: 475px;
                            }
                    }
                }
                }
                &.user-msgbox-container{
                                &.show {
                                  .card {
                                    .card-body {
                                        .list-group {
                                             height: 400px;
                                }
                            }
                        }
                    }
                            }
            }
        }
    }
    &.view_sample-wrapper{
        .msgContainerBox{
            position: absolute;
            top: 0px;
            right: 10px;
            bottom:0;
            // height: calc(100% - 35px);
            // max-width: 410px;
            .msgbox-container{
                &.show{
                    .card{ 
                        .card-body{ 
                            .list-group{
                                height: 650px;
                            }
                    }
                }
                }
                &.user-msgbox-container{
                                &.show {
                                  .card {
                                    .card-body {
                                        .list-group {
                                             height: 435px;
                                }
                            }
                        }
                    }
                            }
            }
            &.view-sample-msgContainerBox{
                position: absolute;
                top: 0px;
                right: 10px;
                bottom: 0;
                //height: calc(100% - 35px);
                // max-width: 410px;
                .msgbox-container{
                    &.show{
                        .card{ 
                            .card-body{ 
                                .list-group{
                                    height: 600px !important;
                                }
                        }
                    }
                    }
                    &.user-msgbox-container{
                                    &.show {
                                      .card {
                                        .card-body {
                                            .list-group {
                                                 height: 400px;
                                    }
                                }
                            }
                        }
                                }
                }
            }
        }
        &.viewSupport-req{
        .msgContainerBox{
            position: absolute;
            top: 0 !important;
            right: 10px;
            bottom:0;
            // height: calc(100% - 35px);
            // max-width: 410px;
            .msgbox-container{
                &.show{
                    .card{ 
                        .card-body{ 
                            .list-group{
                                height: 600px !important;
                            }
                    }
                }
                }
            }
        }
        }
    }
    &.viewOfferRequest--wrapper {
        background-color: unset;

        .msgContainerBox {
            .msgbox-container {
                &.show {
                    .card {
                        .card-body {
                            .list-group {
                                height: 600px;
                                overflow-y: auto;
                            }
                        }
                    }
                }
            }
        }
    }
   
   
}

.msgContainerBox{
    position: absolute;
    top: 0px;
    right: 10px;
    bottom :0;
    //height: calc(100% - 35px);
    // max-width: 410px;
}
        .view-detail-for-admin {
            position: absolute;
            top: 0;
            right: -20px;
            left: 0px;
            bottom: 0;
            z-index: 9;
            // box-shadow: 0px 4px 25px 0px #0000001A;
            background-color: #00000057;
            display: flex;
            justify-content: end;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            .view-detail-left{
                background-color: transparent;
                width: 25%;
            }
            .view-detail-inner {
                width: 75%;
                background-color: #FFFFFF;
                border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 20px;
                .header {
                    .header-inner {
                        .close-btn {
                            color: #8F9195;
                            background: transparent;
                            border: unset;
                            box-shadow: unset;
                        }
    
                        .main-heading {
                            font-family: Segoe UI;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 31.92px;
                            color: $primaryTextColor;
                            margin-bottom: 5px;
                        }
    
                        .sub-heading {
                            font-family: Segoe UI;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 19.95px;
                            color: #737376;
                        }
                    }
                }
    
                .view-detail-body {
                    .left-inner {
                        .left-heading {
                            p {
                                font-family: Segoe UI;
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 22px;
                                color: $primaryHeadingColor;
                            }
                        }
    
                        .left-content {
                            max-height: 500px;
                            overflow-y: auto;
    
                            &::-webkit-scrollbar {
                                width: 5px;
                            }
    
                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #FFFFFF;
                                border-radius: 5px;
                                // height: 30px;
                            }
    
                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #D9D9D9;
                                border-radius: 5px;
                                height: 30px;
                            }
    
                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #555;
                            }
    
                            p {
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                color: $primaryHeadingColor;
                                padding-right: 10px;
                            }
                        }
    
                        .left-sub-content {
                            p {
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                color: $primaryHeadingColor;
                            }
                        }
    
                        .left-sub-heading {
                            p {
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 25px;
                                color: $primaryHeadingColor;
                            }
                        }
                    }
    
                    .right-inner {
                        border-radius: 20px;
                        padding: 20px;
                        background-color: $chineseWhite;
                        height: calc(100% - 15px);
                        overflow-y: auto;
                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #FFFFFF;
                            border-radius: 5px;
                            // height: 30px;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #D9D9D9;
                            border-radius: 5px;
                            height: 30px;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
    
                        td {
                            padding: 10px 20px 10px 10px;
    
                            &.key {
                                font-family: Segoe UI;
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 22px;
                                color: $DeepSpaceSparkle;
                            }
    
                            &.value {
                                font-family: Segoe UI;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 22px;
                                color: $primaryHeadingColor;
                            }
                        }
                    }
                }
            }
        }
        .view-detail-for-user {
            position: absolute;
            top: 0;
            right: -20px;
            left: 0px;
            bottom: 0;
            // bottom: -20px;
            z-index: 9;
            // box-shadow: 0px 4px 25px 0px #0000001A;
            background-color: #00000057;
            display: flex;
            justify-content: end;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            .view-detail-left{
                background-color: transparent;
                width: 25%;
            }
            .view-detail-inner {
                width: 75%;
                background-color: #FFFFFF;
                border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 20px;
                .header {
                    .header-inner {
                        .close-btn {
                            color: #8F9195;
                            background: transparent;
                            border: unset;
                            box-shadow: unset;
                        }
    
                        .main-heading {
                            font-family: Segoe UI;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 31.92px;
                            color: $primaryTextColor;
                            margin-bottom: 5px;
                        }
    
                        .sub-heading {
                            font-family: Segoe UI;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 19.95px;
                            color: #737376;
                        }
                    }
                }
    
                .view-detail-body {
                    .left-inner {
                        .left-heading {
                            p {
                                font-family: Segoe UI;
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 22px;
                                color: $primaryHeadingColor;
                            }
                        }
    
                        .left-content {
                            max-height: 525px;
                            overflow-y: auto;
    
                            &::-webkit-scrollbar {
                                width: 5px;
                            }
    
                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #FFFFFF;
                                border-radius: 5px;
                                // height: 30px;
                            }
    
                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #D9D9D9;
                                border-radius: 5px;
                                height: 30px;
                            }
    
                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #555;
                            }
    
                            p {
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                color: $primaryHeadingColor;
                                padding-right: 10px;
                            }
                        }
    
                        .left-sub-content {
                            p {
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                color: $primaryHeadingColor;
                            }
                        }
    
                        .left-sub-heading {
                            p {
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 25px;
                                color: $primaryHeadingColor;
                            }
                        }
                    }
    
                    .right-inner {
                        border-radius: 20px;
                        padding: 20px;
                        background-color: $chineseWhite;
                        height: calc(100% - 0px);
                        overflow-y: auto;
                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #FFFFFF;
                            border-radius: 5px;
                            // height: 30px;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #D9D9D9;
                            border-radius: 5px;
                            height: 30px;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                        td {
                            padding: 10px 20px 10px 10px;
    
                            &.key {
                                font-family: Segoe UI;
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 22px;
                                color: $DeepSpaceSparkle;
                            }
    
                            &.value {
                                font-family: Segoe UI;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 22px;
                                color: $primaryHeadingColor;
                            }
                        }
                    }
                }
            }
        }
        .admin-message-box {
            position: absolute;
            top: 0;
            right: -20px;
            left: 0;
            bottom: 0;
            z-index: 90;
            background-color: #00000057;
            display: flex;
            justify-content: end;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            .admin-message-box-left-inner{
                background-color: transparent;
                width: 25%;
            }

            .admin-message-box-inner {
                background-color: #FFFFFF;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                padding: 20px;
                width: 75%;


                // Sidebar Styling
                .sidebar {

                    .buyer-info-wrapper {
                        border-radius: 20px;
                        background-color: #E3EBEC;
                        padding: 10px 15px;
                        position: relative;
                        cursor: pointer;

                        .buyer-text {
                            font-family: Segoe UI;
                            font-size: 15px;
                            font-weight: 600;
                            line-height: 19.95px;
                            text-align: left;
                            color: #636363;
                            margin-bottom: 5px;
                        }

                        .buyer-name {
                            font-family: Segoe UI;
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 23.94px;
                            color: #636363;
                            margin: 0;

                        }

                        .msg-count-wrapper {
                            position: absolute;
                            top: 10px;
                            right: 15px;
                            padding: 5px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 25px;
                            width: 25px;
                            background-color: #525E9D;
                            font-family: Segoe UI;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 15.96px;
                            color: #FFFFFF;
                        }
                    }

                    .seller-list {
                        background-color: #F0F0F0;
                        border-radius: 20px;
                        margin-top: 20px;
                        // height: 760px;
                        overflow-y: hidden;

                        .seller-list-header {
                            padding: 10px;
                            border-bottom: 1px solid #D9D9D9;

                            .heading {
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 21.28px;
                                text-align: left;
                                color: #333333;
                                margin: 0;
                                padding-inline: 5px;
                            }

                            .chat-search {
                                border-radius: 100px;
                                background-color: #FDFCFA;
                                border: unset;

                                &::placeholder {
                                    color: #6F6F6F;
                                    font-family: Segoe UI;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 21.28px;
                                }
                            }
                        }
                        .list-group{
                            height: 605px;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                                width: 5px;
                            }
                
                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: transparent;
                                border-radius: 5px;
                            }
                
                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #888;
                                border-radius: 5px;
                            }
                
                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #555;
                            }
                        .seller {
                            margin: 10px 18px;
                            margin-bottom: 0px;
                            background-color: transparent;
                            padding: 0px;
                            padding-bottom: 10px;

                            .seller-inner {
                                padding:12px 12px;
                                border-radius: 10px;
                                position: relative;
                                cursor: pointer;

                                .seller-name {
                                    font-family: Segoe UI;
                                    font-size: 18px;
                                    font-weight: 700;
                                    line-height: 23.94px;
                                    color: #525E9D;
                                    padding-right: 30px;
                                }

                                .seller-item-list {
                                    list-style-type: none;
                                    padding: 0;
                                    margin: 0;
                                    background-color: transparent;

                                    .seller-list-item {
                                        font-family: Segoe UI;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 18.62px;
                                        color: #333333;
                                        padding-block: 3px;
                                    }
                                }

                                .msg-count-wrapper {
                                    position: absolute;
                                    top: 12px;
                                    right: 12px;
                                    padding: 5px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 25px;
                                    width: 25px;
                                    background-color: #525E9D;
                                    font-family: Segoe UI;
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 15.96px;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                    }
                }

                // Chat Area Styling
                .chat {
                    // display: flex;
                    // flex-direction: column;
                    background-color: #fff;

                    .chat-header {
                        padding: 15px 0px;
                        border-bottom: 1px solid #D9D9D9;

                        .chat-search {
                            background-color: #FDFCFA;
                            border: 1px solid #ABABAB;
                            border-radius: 100px;

                            &::placeholder {
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 21.28px;
                                text-align: left;
                                color: #6F6F6F;
                            }
                        }
                    }
                    .message-list-card{
                    .message-box-header{
                        display: none;
                    }

                    .card {
                        border: unset;
                        position: unset;

                        .card-body {
                            padding-inline: 0px;
                            border-bottom: 1px solid #D9D9D9 ;
                            height: 610px;
                            overflow-y: auto;
                            padding-right: 5px;
                            &::-webkit-scrollbar {
                                width: 5px;
                            }
                
                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: transparent;
                                border-radius: 5px;
                            }
                
                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #888;
                                border-radius: 5px;
                            }
                
                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #555;
                            }
                            .list-group {
                                .list-group-item {
                                    padding-inline: 0px;
                                    border: unset;
                                    .sender {
                                        max-width: 280px;
                                        .sender__msg {
                                            background-color: #8F9195;
                                            padding: 10px 15px;
                                            border-radius: 10px;
                                            border-bottom-right-radius: unset;

                                            pre {
                                                font-family: Segoe UI;
                                                font-size: 14px;
                                                font-weight: 400;
                                                line-height: 19.6px;
                                                text-align: left;
                                                color: #FFFFFF;
                                                overflow-x: hidden;
                                                white-space: wrap;
                                            }
                                        }

                                        .sender__info {
                                            .sender__info--name {
                                                font-family: Segoe UI;
                                                font-size: 13px;
                                                font-weight: 700;
                                                line-height: 18.2px;
                                                text-align: left;
                                                color: #616161;
                                                text-transform: capitalize;
                                            }

                                            .sender__info--time {
                                                //styleName: chat/message-stamp;
                                                font-family: Inter;
                                                font-size: 13px;
                                                font-weight: 400;
                                                line-height: 15.6px;
                                                text-align: left;
                                                color: #737376;
                                            }

                                            .sender__info--status {
                                                color: #95CF48;
                                            }
                                        }
                                    }
                                    .avatar{
                                        display: none;
                                    }

                                    .receiver {
                                        max-width: 280px;
                                        .receiver__info {
                                            .receiver__info--name {
                                                font-family: Segoe UI;
                                                font-size: 13px;
                                                font-weight: 700;
                                                line-height: 18.2px;
                                                text-align: left;
                                                color: #616161;
                                                text-transform: capitalize;
                                                margin-bottom: 5px;
                                            }

                                            .receiver__info--time {
                                                //styleName: chat/message-stamp;
                                                font-family: Inter;
                                                font-size: 13px;
                                                font-weight: 400;
                                                line-height: 15.6px;
                                                color: #737376;
                                            }
                                        }

                                        .receiver__msg {
                                            background-color: #F0F0F0;
                                            padding: 10px 15px;
                                            border-radius: 10px;
                                            border-top-left-radius: unset;

                                            pre {
                                                font-family: Segoe UI;
                                                font-size: 14px;
                                                font-weight: 400;
                                                line-height: 19.6px;
                                                text-align: left;
                                                color: #000000;
                                                overflow-x: hidden;
                                                white-space: wrap;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .card-footer {
                            padding-inline: 0;
                            bottom: 0px !important;
                
                            .send-msg {
                              .form-control {
                                padding: 1.375rem .75rem;
                                border-radius: 10px;
                              }
                            }
                          }

//                                                                                 .card-footer {
//                                                                                     padding: 1rem 0.75rem;
//                                                                                     border-radius: 10px;
//                                                                                     border: 1px solid #686764;
//                                                                                     margin-top: 10px;
    
//                                                                                     .send-msg {
//                                                                                         display: flex;
//                                                                                         justify-content: space-between;
//                                                                                         align-items: center;
//                                                                                         gap: 10px;
    
//                                                                                         div {
//                                                                                             width: calc(100% - 100px);
    
//                                                                                             // .send-input-inner {
//                                                                                             //     width: 100%;
    
//                                                                                                 input {
//                                                                                                     border: unset;
//                                                                                                     border-radius: unset;
//                                                                                                     box-shadow: unset;
//                                                                                                     width: 100%;
    
//                                                                                                     &:focus {
//                                                                                                         box-shadow: unset;
//                                                                                                     }
    
//                                                                                                     &::placeholder {
//                                                                                                         font-family: Segoe UI;
// font-size: 15px;
// font-weight: 400;
// line-height: 20.25px;
// color: #737376;

//                                                                                                     }
//                                                                                                 }
    
//                                                                                                 .form-control {
//                                                                                                     padding: unset;
//                                                                                                     border-radius: unset;
//                                                                                                 }
//                                                                                             // }
    
//                                                                                             .file__upload--info {
//                                                                                                 border: 1px solid #D9D9D9;
//                                                                                                 border-radius: 4px;
//                                                                                                 padding: 0px;
//                                                                                                 height: auto;
    
//                                                                                                 .file-name {
//                                                                                                     font-family: Segoe UI;
//                                                                                                     font-size: 13px;
//                                                                                                     font-weight: 400;
//                                                                                                     line-height: 17.29px;
//                                                                                                     text-align: left;
//                                                                                                     color: #8F9195;
//                                                                                                     padding: 4px;
//                                                                                                 }
    
//                                                                                                 .trash-img-wrapper {
//                                                                                                     background-color: #F0F0F0;
//                                                                                                     padding: 4px;
//                                                                                                     // padding-top: 1px;
//                                                                                                     border-top-right-radius: 5px;
//                                                                                                     border-bottom-right-radius: 5px;
//                                                                                                     cursor: pointer;
//                                                                                                 }
//                                                                                             }
//                                                                                         }
//                                                                                     }
//                                                                                 }
                    }
                }
                }

            }
        }

    // .inventory-doc-table {

    // }
}
.viewdetail-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #333;
    font-size: 18px;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3411764706);
    z-index: 2;

    &::before {
        content: '';
        display: block;
        margin: 20px auto;
        width: 50px;
        height: 50px;
        border: 5px solid #f3f3f3;
        /* Light grey */
        border-top: 5px solid #0C3344;
        /* Blue */
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    &::after {
        content: 'Please Wait!';
        display: block;
        margin-top: 10px;
        color: #0C3344;
        background-color: transparent;
        font-weight: 700;
    }

}
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }