.dropdown-menu {
    &.show {
        border: unset;
        background-color: $white;
        box-shadow: 0px 0px 15px 0px #0000001A;
        z-index: 99;

        &:hover {
            color: #FAF8F6;
        }
    }

    li {
        .dropdown-item {
            color: $primaryTextColor;
            font-weight: 600;
            cursor: pointer;
        }
    }


}

.dropdown-status {
    .btn {
        padding: 4px 6px;
        line-height: 1;
        font-size: 14px;

        &:disabled {
            cursor: not-allowed;
            opacity: 1;
        }

        &.btn-success {
            background-color: #F99600;
            color: #FFF;
            border: 1px solid transparent;
        }

        &.btn-primary {
            background-color: #acafb1;
            color: #FFF;
            border: 1px solid transparent;
        }

        &.btn-danger {
            background-color: #ff0000c2;
            color: #FFF;
            border: 1px solid transparent;
        }

        &.btn-dark {
            background-color: red;
            color: #FFF;
            border: 1px solid transparent;
        }

        &.btn-info {
            background-color: #50b1ba;
            color: #FFF;
            border: 1px solid transparent;
        }

        &.btn-secondary {
            background-color: green;
            color: #FFF;
            border: 1px solid transparent;
        }

        &.dropdown-toggle {
            &::before {
                content: unset;
            }
        }
    }

    .dropdown-menu {
        &.show {
            border: 1px solid #0000002d;
            background-color: #FFF;
        }

        a {
            &.dropdown-item {
                display: flex;
                line-height: 1;
                align-items: center;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                line-height: normal;

                &:hover {
                    background-color: #FAF8F6;
                }

                &:active {
                    background-color: #FAF8F6;
                }
            }
        }
    }
}

.dropdown-item {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: normal;
    font-weight: 600;

    &:active {
        background-color: #FAF8F6;
    }

    &.dropdown-item-submit-for-review {
        color: #952698;
    }

    &.dropdown-item-approved {
        color: #FF8B03;
    }

    &.dropdown-item-pending-review {
        color: #737376;
    }

    &.dropdown-item-rejected {
        color: #EC2323;
    }

    &.dropdown-item-shipped {
        color: #28C1B8;
    }

    &.dropdown-item-cancelled {
        color: #B10E1C;
    }

    &.dropdown-item-delivered {
        color: #25B634;
    }

    &.dropdown-item-ownership-transferred {
        color: #4687D4;
    }

    &.dropdown-item-contract-signed {
        color: #9025B6;
    }

    &.dropdown-item-pending-for-Contract {
        color: #80A72D;
    }

    &.dropdown-item-under-review {
        color: #D849B0;
    }

    &.dropdown-item-accepted {
        color: #5EE026;
    }

    &.dropdown-item-finalized {
        color: #39678A;
    }

    &.dropdown-item-published {
        color: #7E9C97;
    }

    &.dropdown-item-unpublished {
        color: #E48486;
    }

    &.dropdown-item-contract-sent {
        color: #D3A54D;
    }

    &.dropdown-item-contract-executed {
        color: #B89273;
    }

    &.dropdown-item-invoice-sent {
        color: #9BB078;
    }

    &.dropdown-item-payment-received {
        color: #586C53;
    }

    &.dropdown-item-under-review-by-seller {
        color: #965861;

    }

    &.dropdown-item-in-transit {
        color: #1c0e6be0;
    }

    &.dropdown-item-seller-accepted {
        color: #75BC84;
    }

    &.dropdown-item-seller-rejected {
        color: #FE3D3D;
    }

    &.dropdown-item-under-review-by-wdtp {
        color: #CEAD26;
    }

    &.dropdown-item-invoice-raised {
        color: #C77CC1;
    }

    &.dropdown-item-invite {
        color: #328fa8;
    }
    
    &.dropdown-item-reject-for-owner {
        color: #C72611;
    }

    &.dropdown-item-accept-for-owner {
        color: #30A373;
    }

    &.dropdown-item-send-to-owner {
        color: #3A4BA1;
    }

    &.dropdown-item-admin-rejected {
        color: #EC2347;
    }

    &.dropdown-item-admin-accepted {
        color: #25B634;
    }

    &.dropdown-item-accepted-rejected {
        color: #B89273;
    }

    &.dropdown-item-action-required {
        color: #4687D4;
    }
    &.dropdown-item-open{
        color: #3A809E;
    }
    &.dropdown-item-reopen{
        color: #3A809E;
    }
    &.dropdown-item-closed{
        color:  #B10E1C;
    }
    &.dropdown-item-in-progress{
        color:  #525E9D;
    }
    &.dropdown-item-uploaded {
        color: #39678A;
    }
    &.dropdown-item-upload-failed {
        color: #EC2347;
    }
}

.dropdown-status {
    background-color: transparent;
    border: none;
    font-weight: 600;

    &.dropdown-toggle {
        &::before {
            content: none;
        }

        &:disabled {
            opacity: 1;
        }

        &::after {
            content: none;
        }
    }

    &.btn-submit-for-review {
        color: #952698;

        &:active {
            color: #952698;
        }
    }

    &.btn-approved {
        color: #FF8B03;

        &:active {
            color: #FF8B03;
        }
    }

    &.btn-in-transit {
        color: #1c0e6be0;

        &:active {
            color: #1c0e6be0;
        }
    }

    &.btn-pending-review {
        color: #737376;

        &:active {
            color: #737376;
        }
    }

    &.btn-rejected {
        color: #EC2323;

        &:active {
            color: #EC2323;
        }
    }

    &.btn-shipped {
        color: #28C1B8;

        &:active {
            color: #28C1B8;
        }
    }

    &.btn-cancelled {
        color: #B10E1C;

        &:active {
            color: #B10E1C;
        }
    }

    &.btn-delivered {
        color: #25B634;

        &:active {
            color: #25B634;
        }
    }

    &.btn-ownership-transferred {
        color: #4687D4;

        &:active {
            color: #4687D4;
        }
    }

    &.btn-contract-signed {
        color: #9025B6;

        &:active {
            color: #9025B6;
        }
    }

    &.btn-pending-for-Contract {
        color: #80A72D;

        &:active {
            color: #80A72D;
        }
    }

    &.btn-under-review {
        color: #D849B0;

        &:active {
            color: #D849B0;
        }
    }

    &.btn-accepted {
        color: #5fb73a;

        &:active {
            color: #5fb73a;
        }
    }

    &.btn-finalized {
        color: #39678A;

        &:active {
            color: #39678A;
        }
    }

    &.btn-published {
        color: #7E9C97;

        &:active {
            color: #7E9C97;
        }
    }

    &.btn-unpublished {
        color: #E48486;

        &:active {
            color: #E48486;
        }
    }

    &.btn-contract-sent {
        color: #D3A54D;

        &:active {
            color: #D3A54D;
        }
    }

    &.btn-contract-executed {
        color: #B89273;

        &:active {
            color: #B89273;
        }
    }

    &.btn-invoice-sent {
        color: #9BB078;

        &:active {
            color: #9BB078;

        }
    }

    &.btn-payment-received {
        color: #586C53;

        &:active {
            color: #586C53;
        }
    }

    &.btn-under-review-by-seller {
        color: #965861;

        &:active {
            color: #965861;
        }
    }

    &.btn-seller-accepted {
        color: #75BC84;


        &:active {
            color: #75BC84;
        }
    }

    &.btn-seller-rejected {
        color: #FE3D3D;

        &:active {
            color: #FE3D3D;
        }
    }

    &.btn-under-review-by-wdtp {
        color: #CEAD26;

        &:active {
            color: #CEAD26;
        }
    }

    &.btn-invoice-raised {
        color: #C77CC1;

        &:active {
            color: #C77CC1;
        }
    }

    &.btn-invite {
        color: #328fa8;

        &:active {
            color: #328fa8;
        }
    }

    &.btn-reject-for-owner {
        color: #C72611;
        &:active{
            color: #C72611;
        }
    }

    &.btn-send-to-owner {
        color: #3A4BA1;
        &:active{
            color: #3A4BA1;
        }
    }

    &.btn-accept-for-owner {
        color: #30A373;
        &:active{
            color: #30A373;
        }
    }

    &.btn-admin-rejected {
        color: #EC2347;
        &:active{
            color: #EC2347;
        }
    }

    &.btn-admin-accepted {
        color: #25B634;
        &:active{
            color: #25B634;
        }
    }

    &.btn-accepted-rejected {
        color: #B89273;
        &:active{
            color: #B89273;
        }
    }

    &.btn-action-required {
        color: #4687D4;
        &:active{
            color: #4687D4;
        }
    }
    &.btn-closed{
        color:  #B10E1C;
        &:active{
            color:  #B10E1C;
        }
    }
    &.btn-reopen{
        color: #3A809E;
        &:active{
            color: #3A809E;
        }
    }
    &.btn-open{
        color: #3A809E;
        &:active{
            color: #3A809E;
        }
    }

    &.btn-in-progress{
        color:  #525E9D;
        &:active{
            color: #525E9D;
        }
    }

    &.btn-uploaded{
        color:  #39678A;
        &:active{
            color: #39678A;
        }
    }

    &.btn-upload-failed{
        color:  #EC2347;
        &:active{
            color: #EC2347;
        }
    }

    &.btn-none {
        font-family: Segoe UI;
        font-size: 15px;
        font-weight: 600;
        line-height: 19.95px;
        color: #4687D4;
        &:active{
            color: #4687D4;
        }
    }
}